var site = site || {};
var generic = generic || {};
(function($) {
  site.facebook_login_hk = (function() {
    var nodes = {};
    var _getDOMNodes = function() {
      nodes.socialLoginFbContainer = $('.social_login_fb');
      nodes.socialLoginEmailOptin = $('.social-login__email-opt-in');
      nodes.socialLoginPromotion = nodes.socialLoginEmailOptin.find('input[name="FB_PC_ALL_PROMOTIONS"]');
      nodes.socialEmailPromoNode = nodes.socialLoginFbContainer.find('input[name="PC_EMAIL_PROMOTIONS"]');
      nodes.socialSmsPromoNode = nodes.socialLoginFbContainer.find('input[name="SMS_PROMOTIONS"]');
      nodes.socialPostalPromoNode = nodes.socialLoginFbContainer.find('input[name="POSTAL_PROMOTIONS"]');
      nodes.socialPhonePromoNode = nodes.socialLoginFbContainer.find('input[name="PHONE_PROMOTIONS"]');
      nodes.socialSisterPromoNode = nodes.socialLoginEmailOptin.find('input[name="SISTER_BRAND_PROMO"]');
      nodes.socialSisterBrandNode = nodes.socialLoginFbContainer.find('input[name="SISTER_BRAND_PROMOTIONS"]');
      nodes.socialLastSourceNode = nodes.socialLoginFbContainer.find('input[name="LAST_SOURCE"]');
    };
    return {
      init: function() {
        _getDOMNodes();
        var source = 'Facebook_gnav';
        nodes.socialLastSourceNode.val(source);

        nodes.socialSisterPromoNode.on('click', function() {
          nodes.socialSisterPromoNode.prop('checked', $(this).is(':checked'));
        });

        nodes.socialSisterPromoNode.on('change', function() {
          if (nodes.socialSisterPromoNode.is(':checked')) {
            nodes.socialSisterBrandNode.val(0);
          } else {
            nodes.socialSisterBrandNode.val(1);
          }
        });

        nodes.socialLoginPromotion.on('click', function() {
          nodes.socialLoginPromotion.prop('checked', $(this).is(':checked'));
        });

        nodes.socialLoginPromotion.on('change', function() {
          if (nodes.socialLoginPromotion.is(':checked')) {
            nodes.socialLoginPromotion.prop('checked');
            nodes.socialEmailPromoNode.val(1);
            nodes.socialSmsPromoNode.val(1);
            nodes.socialPostalPromoNode.val(1);
            nodes.socialPhonePromoNode.val(1);
          } else {
            nodes.socialEmailPromoNode.val(0);
            nodes.socialSmsPromoNode.val(0);
            nodes.socialPostalPromoNode.val(0);
            nodes.socialPhonePromoNode.val(0);
          }
        });
      }
    };
  }());
  $(document).ready(function() {
    site.facebook_login_hk.init();
  });
})(jQuery);
